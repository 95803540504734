import { useMemo } from "react";
import { Badge, Box, Chip, IconButton, Link } from "@mui/material";
import { Forum, LinkedIn } from "@mui/icons-material";

import EditTextarea from "@/components/EditTextArea";
import RatingContainer from "@/components/RatingContainer";
import { fromNow } from "@/utils";

export const standardColumns = (
  searchData,
  handleCommentClick,
  targets,
  setTargets,
  fetchTargetCount
) => [
  {
    field: "comments",
    width: 80,
    valueFormatter: (value) => {
      return value?.map((comment) => comment.text).join("; ") || "";
    },
    renderCell: ({ row }) => (
      <Box>
        <IconButton onClick={() => handleCommentClick(row)}>
          <Badge
            badgeContent={row.comments?.length}
            color="warning"
            sx={{
              "& .MuiBadge-badge": { fontSize: 10, height: 15, minWidth: 15 },
            }}
          >
            <Forum fontSize="small" color="info" />
          </Badge>
        </IconButton>
      </Box>
    ),
  },
  {
    field: "client_comments",
    editable: true,
    width: 120,
  },
  {
    field: "rating",
    headerName: "stars",
    width: 150,
    type: "number",
    renderCell: (params) => (
      <RatingContainer
        target={params.row}
        targets={targets}
        setTargets={setTargets}
        fetchTargetCount={fetchTargetCount}
      />
    ),
  },
  {
    field: "name",
    editable: true,
  },
  {
    field: "year_founded",
    type: "string",
    width: 80,
    editable: true,
  },
  {
    field: "contact_address",
    editable: true,
  },
  {
    field: "city",
    width: 90,
    editable: true,
  },
  {
    field: "state",
    width: 80,
    editable: true,
  },
  {
    field: "postal_code",
    width: 80,
    editable: true,
  },
  {
    field: "country",
    width: 80,
    editable: true,
  },
  {
    field: "contact_phone",
    editable: true,
  },
  {
    field: "domain",
    renderCell: ({ value }) => (
      <Link
        href={`https://www.${value}`}
        target="_blank"
        underline="hover"
        color="inherit"
      >
        {value}
      </Link>
    ),
  },
  {
    field: "gpt_description",
    width: 200,
    editable: true,
    type: "string",
    renderEditCell: (params) => <EditTextarea {...params} />,
  },
  {
    field: "linkedin",
    headerName: "",
    editable: true,
    width: 50,
    renderCell: ({ value }) =>
      value ? (
        <IconButton href={value} target="_blank">
          <LinkedIn style={{ color: "lightgray" }} />
        </IconButton>
      ) : null,
  },
  {
    field: "employees",
    type: "number",
    width: 100,
    editable: true,
  },
  {
    field: "linkedin_employee_range",
    editable: true,
  },
  {
    field: "ownership",
    width: 100,
    editable: true,
    type: "singleSelect",
    valueOptions: [
      "bootstrapped",
      "investor_backed",
      "public",
      "public_subsidiary",
      "private_subsidiary",
      "private_equity",
      "private_equity_add_on",
    ],
  },
  {
    field: "was_acquired",
    width: 100,
    editable: true,
  },
  {
    field: "justification",
    width: 100,
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
  },
  {
    field: "ultimate_owner",
    width: 100,
    editable: true,
  },
  {
    field: "designation",
    width: 120,
    editable: true,
    type: "singleSelect",
    valueOptions: ["Minority-Owned", "Woman-Owned", "Other", "None"],
  },
  {
    field: "contact_title",
    editable: true,
  },
  {
    field: "contact_name",
    editable: true,
  },
  {
    field: "contact_first_name",
    editable: true,
  },
  {
    field: "contact_last_name",
    editable: true,
  },

  {
    field: "contact_email",
    editable: true,
  },
  {
    field: "industry",
    editable: true,
  },
  {
    field: "business_type",
    editable: true,
    type: "singleSelect",
    valueOptions: ["Manufacturer", "Distributor", "Service"],
    renderCell: ({ value }) => value,
  },
  {
    field: "products",
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => (
      <Box>
        {value &&
          value
            ?.split(",")
            .map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                color={
                  searchData?.meta?.products?.includes(val.trim())
                    ? "primary"
                    : "default"
                }
                size="small"
                style={{ margin: "0 5px 5px 0" }}
              />
            ))}
      </Box>
    ),
  },
  {
    field: "services",
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => (
      <Box>
        {value &&
          value
            ?.split(",")
            .map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                size="small"
                color={
                  searchData?.meta?.services?.includes(val.trim())
                    ? "success"
                    : "default"
                }
                style={{ margin: "0 5px 5px 0" }}
              />
            ))}
      </Box>
    ),
  },
  {
    field: "end_market",
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => (
      <Box>
        {value &&
          value
            ?.split(",")
            .map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                size="small"
                color={
                  searchData?.meta?.end_market?.includes(val.trim())
                    ? "primary"
                    : "default"
                }
                style={{ margin: "0 5px 5px 0" }}
              />
            ))}
      </Box>
    ),
  },
  {
    field: "revenue_estimates",
    type: "number",
    editable: true,
    valueGetter: (params) => {
      // Check if the value is undefined or null
      if (params?.value == null) {
        return null; // or return a default value like 0
      }
      // Parse to Int if the value exists
      return parseInt(params.value);
    },
  },
  {
    field: "location_count",
    type: "number",
    editable: true,
  },
  {
    field: "facility_size",
    editable: true,
  },
  {
    field: "geographies",
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => (
      <Box>
        {value &&
          value
            ?.split(",")
            .map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                size="small"
                color={
                  searchData?.meta?.geographies?.includes(val.trim())
                    ? "secondary"
                    : "default"
                }
                style={{ margin: "0 5px 5px 0" }}
              />
            ))}
      </Box>
    ),
  },

  {
    field: "source",
    editable: true,
    renderCell: ({ value }) => (
      <Box>
        {value &&
          value
            .split(",")
            .map((val, index) => (
              <Chip
                label={val.trim()}
                key={index}
                color="primary"
                size="small"
                style={{ margin: "0 5px 5px 0" }}
              />
            ))}
      </Box>
    ),
  },

  {
    field: "updated",
    width: 120,
    renderCell: ({ row }) => {
      if (!row.updated) return "";
      return fromNow(row.updated);
    },
  },
  {
    field: "updated_by",
    width: 120,
  },

  {
    field: "change_date",
  },
];

const createCustomColumns = (customColumns) => {
  return customColumns.map((columnName) => ({
    field: columnName,
    width: 80,
    editable: true,
    type: "string",
  }));
};

export const getCustomColumns = (searchData) => {
  return searchData?.meta?.custom_columns
    ? createCustomColumns(searchData.meta.custom_columns)
    : [];
};

export const getColumns = (
  searchData,
  handleCommentClick,
  targets,
  setTargets,
  fetchTargetCount
) => {
  console.log("getColumns is fired");

  return [
    ...standardColumns(
      searchData,
      handleCommentClick,
      targets,
      setTargets,
      fetchTargetCount
    ),
    ...getCustomColumns(searchData),
  ];
};

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Card,
} from "@mui/material";
import { ArrowBackIos, DataUsage } from "@mui/icons-material";
import Consistency from "./Consistency";
import Leaderboard from "./Leaderboard";
import Searches from "./Searches";
import Time from "./Time";

import { API_BASE } from "../../App";
import SankeyDiagram from "@/components/SankeyDiagram";

export default function Reporting({}) {
  const actorKey = localStorage.getItem("actorKey");
  const ADMINS = [
    "7138248581",
    "3109166195",
    "3125740050",
    "skidder@genzassociates.com",
    "parker@genzassociates.com",
  ];

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 14)) // Subtract 14 days
      .toISOString()
      .split("T")[0] // Format to YYYY-MM-DD
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const getResults = async () => {
    try {
      setLoading(true);
      const endpoint = API_BASE + "/data/leaderboard";
      const response = await axios.get(endpoint, {
        params: {
          actor_key: localStorage.getItem("actorKey"),
          start_date: startDate,
          end_date: endDate,
        },
      });

      setResults(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Reporting useEffect");
    getResults();
  }, [startDate, endDate]);

  return (
    <Box sx={{ pb: 10 }}>
      <PageAppBar />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
        <Box sx={{ display: "flex", gap: 2, px: 4, alignItems: "center" }}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* <Button onClick={getResults}>Refresh</Button> */}
          {loading && <CircularProgress />}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 4 }}>
          <Leaderboard
            results={results}
            loading={loading}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              sorting: { sortModel: [{ field: "send", sort: "desc" }] },
              rowGrouping: { model: ["analyst", "search"] },
              columns: {
                columnVisibilityModel: {
                  analyst: false,
                  date: false,
                  search: false,
                  comment: false,
                },
              },
              aggregation: {
                model: {
                  reject: "sum",
                  advance: "sum",
                  rating: "sum",
                  send: "sum",
                  comment: "sum",
                  client_approve: "sum",
                  hours: "sum",
                  search_uid: "max",
                },
              },
            }}
            title="Leaderboard"
          />

          <Leaderboard
            results={results}
            loading={loading}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              sorting: { sortModel: [{ field: "date", sort: "desc" }] },
              columns: {
                columnVisibilityModel: {
                  comment: false,
                },
              },
              aggregation: {
                model: {
                  reject: "sum",
                  advance: "sum",
                  rating: "sum",
                  send: "sum",
                  client_approve: "sum",
                  comment: "sum",
                  hours: "sum",
                },
              },
            }}
            title="Recent Events | By Analyst by Search by Date"
          />
        </Box>

        {/* <SankeyDiagram /> */}
        <Searches />
        <Consistency />
        <Time />
      </Box>
    </Box>
  );
}

function PageAppBar() {
  return (
    <AppBar position="static" color="transparent">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => window.history.back()}>
          <ArrowBackIos />
        </IconButton>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <DataUsage color="secondary" />
          <Typography variant="h5">TargetSelect Reporting</Typography>
        </Box>
        <Box />
      </Toolbar>
    </AppBar>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Drawer,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  IconButton,
} from "@mui/material";

import { Close, Description, Info, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Save, Settings } from "@mui/icons-material";

import { clearColumnOrder } from "@/utils";

import EventHistoryContainer from "@/components/EventHistoryContainer";

import { API_BASE } from "@/App.js";

export default function NoteDrawer({ searchData }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [notes, setNotes] = useState("");
  const [sources, setSources] = useState("");
  const [products, setProducts] = useState("");
  const [services, setServices] = useState("");
  const [endCustomer, setEndCustomer] = useState("");
  const [geographies, setGeographies] = useState("");
  const [customColumns, setCustomColumns] = useState([]);
  const [businessType, setBusinessType] = useState("");
  const [size, setSize] = useState("");

  useEffect(() => {
    if (!searchData) return;

    setNotes(searchData.meta?.notes || "");
    setProducts(searchData.meta.products || "");
    setServices(searchData.meta.services || "");
    setEndCustomer(searchData.meta.end_customer || "");
    setGeographies(searchData.meta.geographies || "");
    setSources(searchData.meta.sources || "");
    setBusinessType(searchData.meta.business_type || "");
    setSize(searchData.meta.size || "");
    setCustomColumns(searchData.meta.custom_columns || []);
  }, [searchData]);

  const handleSave = async () => {
    setLoading(true);
    const endpoint = API_BASE + "update_search";

    try {
      const response = await axios.post(endpoint, {
        search_uid: searchData.uid,
        data: {
          notes: notes,
          products: products,
          services: services,
          end_customer: endCustomer,
          geographies: geographies,
          sources: sources,
          business_type: businessType,
          size: size,
          custom_columns: customColumns,
        },
        actor_key: localStorage.getItem("actorKey"),
      });

      // Handle the response as needed, e.g., updating UI or state
      console.log("Search updated:", response.data);

      clearColumnOrder(searchData.uid);
      window.location.reload();
    } catch (error) {
      console.error("Error updating search:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <IconButton
        color="info" // color="secondary" if auto ready
        onClick={() => setOpen(!open)}
      >
        <Info />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <IconButton
          onClick={() => setOpen(false)}
          sx={{ position: "absolute", left: 20, top: 10 }}
          color="info"
        >
          <KeyboardDoubleArrowRight />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={loading}
          startIcon={<Save />}
          style={{ borderRadius: "20px", width: '100px', position: 'absolute', right: 20, top: 10 }}
        >
          Save
        </Button>
        {/* <PageAppBar
          setOpen={setOpen}
          loading={loading}
          handleSave={handleSave}
        /> */}

        <Box
          id="right-drawer body"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "800px",
          }}
          sx={{ p: 4, my: 6 }}
        >
          <TextField
            id="outlined-multiline-static"
            label="Research Notes"
            helperText="Client thesis, products, services, geography"
            multiline
            minRows={2}
            maxRows={8}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            sx={{ mb: 4 }}
          />

          <TextField
            id="outlined-multiline-static"
            label="Sources"
            helperText="Sources reviewed"
            multiline
            maxRows={8}
            value={sources}
            onChange={(e) => setSources(e.target.value)}
            style={{ marginBottom: "25px" }}
          />

          <Typography variant="h5" style={{ marginBottom: "25px" }}>
            Client Requirements:
          </Typography>
          <Box sx={{ mb: 4 }}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={customColumns}
              freeSolo
              getOptionLabel={(option) => option}
              value={customColumns}
              onChange={(e, v) => setCustomColumns(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Custom Columns"
                  placeholder="Add custom columns"
                />
              )}
            />
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <TextField
              id="outlined-multiline-static"
              label="Business Type"
              helperText="Manufacturer, Distributor, Service, etc."
              value={businessType}
              select
              onChange={(e) => setBusinessType(e.target.value)}
              style={{ width: "350px", marginBottom: "25px" }}
            >
              <MenuItem value={"Manufacturer"}>Manufacturer</MenuItem>
              <MenuItem value={"Distributor"}>Distributor</MenuItem>
              <MenuItem value={"Service"}>Service</MenuItem>
            </TextField>
            <TextField
              id="outlined-multiline-static"
              label="Size"
              helperText="metrics such as revenue, employees, # reviews"
              value={size}
              multiline
              onChange={(e) => setSize(e.target.value)}
              style={{ width: "350px", marginBottom: "25px" }}
            />
            <TextField
              id="outlined-multiline-static"
              label="Products"
              helperText="csv format"
              value={products}
              multiline
              onChange={(e) => setProducts(e.target.value)}
              style={{ width: "350px", marginBottom: "25px" }}
            />
            <TextField
              id="outlined-multiline-static"
              label="Services"
              helperText="csv format"
              multiline
              value={services}
              onChange={(e) => setServices(e.target.value)}
              style={{ width: "350px", marginBottom: "25px" }}
            />

            <TextField
              id="outlined-multiline-static"
              label="End Customer"
              helperText="csv format"
              value={endCustomer}
              multiline
              onChange={(e) => setEndCustomer(e.target.value)}
              style={{ width: "350px", marginBottom: "25px" }}
            />
            <TextField
              id="outlined-multiline-static"
              label="Geographies"
              helperText="csv format"
              value={geographies}
              multiline
              onChange={(e) => setGeographies(e.target.value)}
              style={{ width: "350px", marginBottom: "25px" }}
            />
          </Box>
          <EventHistoryContainer searchData={searchData} open={open} />
        </Box>
      </Drawer>
    </Box>
  );
}

function PageAppBar({ setOpen, loading, handleSave }) {
  return (
    <AppBar position="sticky" variant="transparent" sx={{ mb: 4 }}>
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSave}
            disabled={loading}
            startIcon={<Save />}
            style={{ borderRadius: "20px" }}
          >
            Save
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

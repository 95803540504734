import { API_BASE } from "@/App";
import { ICON_MAP, LABEL_MAP } from "@/fragments/Constants";
import { MoveDown } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";

export default function MoveTargets({
  searchData,
  targets,
  setTargets,
  fetchTargetCount,
  apiRef,
  currentView,
}) {
  const [moveTo, setMoveTo] = useState(currentView);
  const [loading, setLoading] = useState(false);

  const handleMoveSelectedTargets = async () => {
    const selected = apiRef.current.getSelectedRows();
    const domains = [...selected.keys()];

    setTargets(targets.filter((t) => !domains.includes(t.domain)));
    setLoading(true);

    try {
      await axios.post(API_BASE + "move", {
        search_uid: searchData.uid,
        actor_key: localStorage.getItem("actorKey"),
        domains: domains,
        stage: moveTo,
      });

      fetchTargetCount();
    } catch (error) {
      console.error("Error moving targets:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, my: 4 }}>
      <TextField
        select
        label="Move to"
        value={moveTo}
        onChange={(e) => setMoveTo(e.target.value)}
        style={{ minWidth: "120px" }}
        size="small"
      >
        {Object.keys(LABEL_MAP).map((key) => (
          <MenuItem key={key} value={key}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              {ICON_MAP[key]}
              {LABEL_MAP[key]}
            </Box>
          </MenuItem>
        ))}
      </TextField>

      <Button
        variant="outlined"
        // color="warning"
        onClick={handleMoveSelectedTargets}
        startIcon={loading ? <CircularProgress size={20} /> : <MoveDown />}
        disabled={loading}
      >
        Move Selected
      </Button>
    </Box>
  );
}

import { API_BASE } from "@/App.js";
import ErrorAlert from "@/fragments/ErrorAlert";
import {
  Close,
  ContentCopy,
  Google,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function GoogleSearchDrawer({
  searchData,
  fetchTargets,
  fetchTargetCount,
  currentView,
}) {
  const [open, setOpen] = useState(false);
  const [q, setQ] = useState("");
  const [count, setCount] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [recentSearches, setRecentSearches] = useState([]);

  const location = useLocation();

  const fetchRecentSearches = async () => {
    try {
      const response = await axios.get(API_BASE + "google_search", {
        params: {
          search_uid: searchData.uid,
          actor_key: localStorage.getItem("actorKey"),
        },
      });
      setRecentSearches(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRecentSearches();
  }, []);

  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      const eventResponse = await axios.post(API_BASE + "google_search", {
        search_uid: searchData.uid,
        actor_key: localStorage.getItem("actorKey"),
        q: q,
        count: count,
      });

      if (currentView !== "create") {
        window.location.href = `search?searchKey=${searchData.uid}&currentView=create`;
      }

      setQ("");
      setCount(10);
      fetchRecentSearches(); // Refresh recent searches after a new search
      fetchTargets();
      fetchTargetCount();
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <ErrorAlert error={error} setError={setError} />
      <Box sx={{ display: "flex" }}>
        <IconButton color="primary" onClick={() => setOpen(!open)}>
          <Google />
        </IconButton>
      </Box>
      
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{ position: "absolute", left: 10, top: 10 }}
          color="info"
        >
          <KeyboardDoubleArrowRight />
        </IconButton>
        <Box
          sx={{
            p: 8,
            width: "600px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3" sx={{ mb: 4 }}>
            Google
          </Typography>
          <TextField
            value={q}
            onChange={(e) => setQ(e.target.value)}
            placeholder="🔍  Search Google just like you would on google.com"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === "Enter") {
                handleSubmit(e);
              }
            }}
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
            multiline
            maxRows={8}
          />

          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              gap: 4,
              alignItems: "center",
            }}
          >
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
                sx={{ textTransform: "none" }}
                startIcon={loading && <CircularProgress size={20} />}
              >
                Google Search
              </Button>
            </Box>

            <TextField
              select
              value={count}
              onChange={(e) => setCount(e.target.value)}
              sx={{ width: 120 }}
              size="small"
              label="Results"
            >
              {[10, 20, 50, 100].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {/* Recent Searches Section */}
          {recentSearches.length > 0 && (
            <Box sx={{ mt: 10, width: "100%" }}>
              <Typography variant="body1">Recent Searches</Typography>

              {recentSearches.map((event, index) => (
                <Box
                  key={index}
                  sx={{
                    my: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    opacity: 0.7,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setQ(event.data.q);
                        setCount(event.data.count);
                      }}
                      color="info"
                    >
                      <ContentCopy />
                    </IconButton>
                    <Typography variant="body1">{event.data.q}</Typography>
                  </Box>
                  <Typography variant="caption" color="darkgrey">
                    {event.data.count} results
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
}

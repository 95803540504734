import axios from "axios";
import { useState } from "react";

import {
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  CircularProgress,
  Chip,
} from "@mui/material";

import { AccountTree, Cancel, CheckCircle } from "@mui/icons-material";

import { API_BASE } from "@/App";
import { ICON_MAP, LABEL_MAP } from "@/fragments/Constants";

export default function ResultCard({
  domain,
  currentStage,
  targetStage,
  searchUid,
  source,
  fetchTargets,
  fetchTargetCount,
}) {
  const [submitted, setSubmitted] = useState(false);
  const [display, setDisplay] = useState("flex");
  const [loading, setLoading] = useState(false);

  const moveTarget = async () => {
    try {
      setLoading(true);
      const eventResponse = await axios.post(API_BASE + "transition_event", {
        search_uid: searchUid,
        domain: domain,
        type: targetStage,
        data: {
          source: source.join(", "),
        },
        actor_key: localStorage.getItem("actorKey"),
      });
      setSubmitted(true); // Mark as submitted after the API call
      fetchTargets();
      fetchTargetCount();
    } catch (error) {
      console.error("Error moving target:", error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <Card
      sx={{
        my: 2,
        p: 2,
        display: display,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      {!submitted ? (
        <>
          <IconButton onClick={() => setDisplay("none")}>
            <Cancel color="info" />
          </IconButton>
          <Box>
            <Typography>{LABEL_MAP[currentStage]}</Typography>
          </Box>
          <Typography sx={{ fontWeight: 600 }}>{domain}</Typography>
          <Box>
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <Button
                variant="outlined"
                onClick={moveTarget}
                endIcon={ICON_MAP[targetStage]}
                color="secondary"
                sx={{ borderRadius: 20 }}
              >
                Move to {LABEL_MAP[targetStage]}
              </Button>
            )}
          </Box>
        </>
      ) : (
        // Success message after submission
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CheckCircle color="success" />

          <Typography style={{ fontWeight: 600 }}>{domain}</Typography>
          <Typography>
            {LABEL_MAP[currentStage]} → {LABEL_MAP[targetStage]}
          </Typography>
          <Chip
            icon={<AccountTree />}
            color="primary"
            label={source.join(", ")}
            variant="outlined"
          />
        </Box>
      )}
    </Card>
  );
}

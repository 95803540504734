import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Link,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";

import { AutoAwesome, LinkedIn } from "@mui/icons-material";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";

import Highlighter from "react-highlight-words";

import EventButtonsContainer from "./EventButtonsContainer";
import ChipRow from "./ChipRow";

import CommentsContainer from "@/components/CommentsContainer";

import { API_BASE } from "@/App";
import RatingContainer from "@/components/RatingContainer";

export default function TargetCard({
  searchData,
  target,
  targets,
  setTargets,
  fetchTargetCount,
}) {
  const [display, setDisplay] = useState("");
  const [loading, setLoading] = useState(false);
  const intervalRef = useRef(null);
  const enrichTimeoutRef = useRef(null); // New ref for the 30-second timeout

  const fetchTarget = useCallback(async () => {
    const endpoint = API_BASE + "target";

    setLoading(true);
    try {
      const response = await axios.get(endpoint, {
        params: {
          domain: target.domain,
          search_uid: searchData.uid,
        },
      });

      const unpackedTarget = {
        ...response.data,
        ...(response.data.meta || {}),
      };

      setTargets((prevTargets) =>
        prevTargets.map((t) => {
          if (t.domain === target.domain) {
            return unpackedTarget;
          } else {
            return t;
          }
        })
      );

      if (unpackedTarget.enriched) {
        setLoading(false);
      }
      return unpackedTarget.enriched;
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [target.domain, searchData.uid, setTargets]);

  const enrichTarget = async () => {
    const endpoint = API_BASE + "enrich";
    try {
      const response = await axios.post(endpoint, {
        domain: target.domain,
        search_uid: searchData.uid,
      });
      console.log("Target enriched:", response.data);
    } catch (error) {
      console.error("Failed to enrich target:", error);
    }
  };

  useEffect(() => {
    const pollTarget = async () => {
      if (!target.enriched) {
        const isEnriched = await fetchTarget();
        if (isEnriched) {
          clearInterval(intervalRef.current);
          clearTimeout(enrichTimeoutRef.current); // Clear the 30-second timeout if enriched
          intervalRef.current = null;
          enrichTimeoutRef.current = null;
        }
      } else {
        clearInterval(intervalRef.current);
        clearTimeout(enrichTimeoutRef.current); // Clear the 30-second timeout if enriched
        intervalRef.current = null;
        enrichTimeoutRef.current = null;
      }
    };

    if (!target.enriched && !intervalRef.current) {
      pollTarget(); // Initial call

      // Set up polling every 10 seconds
      intervalRef.current = setInterval(pollTarget, 10000);

      // Set up 30-second timeout to call enrichTarget
      enrichTimeoutRef.current = setTimeout(() => {
        if (!target.enriched) {
          enrichTarget(); // Call enrich endpoint after 30 seconds
        }
      }, 30000); // 30 seconds timeout
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      if (enrichTimeoutRef.current) {
        clearTimeout(enrichTimeoutRef.current);
        enrichTimeoutRef.current = null;
      }
    };
  }, [target.enriched, fetchTarget]);

  return (
    <Card
      variant="outlined"
      style={{ display: display }}
      key={target.domain}
      sx={{ p: 4, my: 4, backgroundColor: "rgba(36,36,36,0.7)" }}
    >
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box id="company">
          <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
            {target.name}
          </Typography>
          {target["linkedin"] && (
            <IconButton href={target["linkedin"]} target="_blank" sx={{ p: 0 }}>
              <LinkedIn sx={{ fontSize: "20px", mr: 1, opacity: 0.7 }} />
            </IconButton>
          )}
          <Link
            href={`https://www.${target.domain}`}
            target="_blank"
            color="primary"
          >
            {target.domain}
          </Link>
        </Box>

        <EventButtonsContainer
          target={target}
          searchData={searchData}
          targets={targets}
          setTargets={setTargets}
          setDisplay={setDisplay}
          fetchTargetCount={fetchTargetCount}
          // enrichTarget={enrichTarget}
        />
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <ChipRow searchData={searchData} target={target} />
          <WasAcquired target={target} />
          <ContactRow target={target} />
          <TargetDescription target={target} />
        </>
      )}

      <CardActions
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: "column",
          px: 0,
        }}
      >
        <Box sx={{ mt: 2, mb: 1 }}>
          <RatingContainer
            target={target}
            targets={targets}
            setTargets={setTargets}
            fetchTargetCount={fetchTargetCount}
          />
        </Box>
        <CommentsContainer searchData={searchData} target={target} />
      </CardActions>
    </Card>
  );
}

function TargetDescription({ target }) {
  return (
    <Box id="target-description" style={{ wordWrap: "break-word" }}>
      <AutoAwesome sx={{ fontSize: "0.8em", mr: 1 }} color="secondary" />
      <Highlighter
        // searchWords={keywords || []}
        searchWords={[]}
        autoEscape={true}
        textToHighlight={target.description || ""}
      />
    </Box>
  );
}

function ContactRow({ target }) {
  const contactString = () => {
    return [
      "contact_name",
      "contact_title",
      "contact_email",
      "contact_phone",
      "contact_address",
    ]
      .map((key) => {
        if (target[key] && target[key].trim() != "") {
          return (
            <span key={key}>
              {target[key]}
              {" • "}
            </span>
          );
        }
      })
      .join("");
  };

  const showContact = () => {
    return contactString() && contactString() != "";
  };

  return (
    <>
      {showContact() && (
        <Box
          style={{
            margin: "20px 5px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ContactEmergencyIcon color="info" />
          <Typography variant="body" style={{ marginLeft: "15px" }}>
            {[
              "contact_name",
              "contact_title",
              "contact_email",
              "contact_phone",
              "contact_address",
            ].map((key) => {
              if (target[key] != "") {
                return (
                  <span key={key}>
                    {target[key]}
                    {" • "}
                  </span>
                );
              }
            })}
          </Typography>
        </Box>
      )}
    </>
  );
}

function WasAcquired({ target }) {
  return (
    <>
      {target.meta.was_acquired != "" && (
        <Box style={{ marginBottom: "10px", width: "100%" }}>
          <Typography variant="caption" color="lightgray">
            was_acquired
          </Typography>
          <Typography variant="body" style={{ marginLeft: "15px" }}>
            {target.meta.was_acquired}
          </Typography>
          <Typography variant="caption" style={{ marginLeft: "15px" }}>
            {target.meta.justification}
          </Typography>
        </Box>
      )}
    </>
  );
}

import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { AddCircle, Close, KeyboardDoubleArrowRight } from "@mui/icons-material";

import { ICON_MAP, LABEL_MAP, SOURCE_OPTIONS } from "@/fragments/Constants";
import { checkDomains } from "@/services/api";

import ResultCard from "./ResultCard";

import { API_BASE } from "@/App";

export default function ImportDrawer({
  searchData,
  fetchTargets,
  fetchTargetCount,
  currentView,
}) {
  const [open, setOpen] = useState(false);

  const [targetStage, setTargetStage] = useState("land");

  const [source, setSource] = useState([]);
  const [csv, setCsv] = useState("");

  useEffect(() => {
    setTargetStage(currentView);
  }, [currentView]);

  // when you set targetstage i want to change the param currentView to targetStage

  return (
    <Box>
      <IconButton color="secondary" onClick={() => setOpen(!open)}>
        <AddCircle />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{ position: "absolute", left: 10, top: 10 }}
          color="info"
        >
          <KeyboardDoubleArrowRight />
        </IconButton>

        <Container maxWidth="md" sx={{ my: 8 }}>
          <Typography variant="h5" gutterBottom>
            Import Targets
          </Typography>
          <Box style={{ display: "flex", justifyContent: "center", gap: 10 }}>
            <Box sx={{ width: "500px" }}>
              <TextField
                select
                value={targetStage}
                onChange={(e) => setTargetStage(e.target.value)}
                required
                label="Stage"
                variant="outlined"
                fullWidth
                sx={{ my: 2 }}
              >
                {Object.keys(LABEL_MAP).map((key) => (
                  <MenuItem key={key} value={key}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      {ICON_MAP[key]}
                      {LABEL_MAP[key]}
                    </Box>
                  </MenuItem>
                ))}
              </TextField>

              <Autocomplete
                multiple
                freeSolo
                value={source}
                onChange={(e, newValue) => setSource(newValue)}
                options={SOURCE_OPTIONS}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Data Source"
                    variant="outlined"
                    helperText="Where did this come from? Select or type + enter."
                    // required
                    sx={{ my: 2 }}
                  />
                )}
              />

              <TextField
                value={csv}
                onChange={(e) => setCsv(e.target.value)}
                required
                // disabled={loading}
                helperText="Add one domain per line / paste from spreadsheet"
                label="Copy/paste domain(s) here"
                autoComplete="off"
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                maxRows={8}
              />
            </Box>
          </Box>
          <ResultsHandler
            csv={csv}
            source={source}
            searchData={searchData}
            targetStage={targetStage}
            fetchTargets={fetchTargets}
            fetchTargetCount={fetchTargetCount}
          />
        </Container>
      </Drawer>
    </Box>
  );
}

function ResultsHandler({
  csv,
  targetStage,
  searchData,
  source,
  fetchTargets,
  fetchTargetCount,
}) {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const importTargets = async () => {
    try {
      setLoading(true);
      const response = await axios.post(API_BASE + "import", {
        search_uid: searchData.uid,
        domains: newDomains,
        stage: targetStage,
        source: source.join(", "), // TBD
        actor_key: localStorage.getItem("actorKey"),
      });

      window.location.href = `/search?searchKey=${searchData.uid}&currentView=${targetStage}`;
    } catch (error) {
      alert(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const checkDomainsHandler = useCallback(
    async (domainsArray) => {
      try {
        setLoading(true);
        const response = await checkDomains(searchData.uid, domainsArray); // Assuming checkDomains is another function
        setResults(response);
      } catch (error) {
        console.error("Error checking domains:", error);
        // You might want to set an error state here and display it to the user
      } finally {
        setLoading(false);
      }
    },
    [searchData.uid, setResults]
  );

  const debouncedCheckDomains = useCallback(
    debounce((domainsArray) => checkDomainsHandler(domainsArray), 500),
    [checkDomainsHandler]
  );

  useEffect(() => {
    if (!csv || csv.trim() === "") {
      // If csv is null or an empty string, clear the results
      setResults([]);
    } else {
      const domainsArray = csv
        .split("\n")
        .map((domain) => domain.trim())
        .filter((domain) => domain !== "");

      if (domainsArray.length > 0) {
        debouncedCheckDomains(domainsArray);
      }
    }
  }, [csv, debouncedCheckDomains]);

  // Filter out "new" results and sort by stage
  const conflictedResults = Object.entries(results)
    .filter(([_, stage]) => stage !== "new")
    .sort(([_, stageA], [__, stageB]) => stageA.localeCompare(stageB));

  const newDomains = Object.entries(results)
    .filter(([_, stage]) => stage === "new") // Filter where value is "new"
    .map(([domain]) => domain); // Extract only the keys (domain names)

  return (
    <Box sx={{my: 2}}>
      {conflictedResults.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            Targets in Search ({conflictedResults.length})
          </Typography>
          {conflictedResults.map(([domain, currentStage]) => (
            <ResultCard
              key={domain}
              domain={domain}
              currentStage={currentStage}
              targetStage={targetStage}
              searchUid={searchData.uid}
              source={source}
              fetchTargetCount={fetchTargetCount}
              fetchTargets={fetchTargets}
            />
          ))}
        </>
      )}
      <Button
        variant="contained"
        onClick={importTargets}
        disabled={loading || newDomains.length === 0}
        sx={{ my: 2, borderRadius: "20px" }}
        size="large"
        fullWidth
      >
        {loading ? (
          <CircularProgress size={30} sx={{ color: "inherit", mr: 1 }} />
        ) : newDomains.length === 0 ? (
          "No New Targets"
        ) : (
          <>
            Import {newDomains.length} New{" "}
            {newDomains.length === 1 ? "Target" : "Targets"}
          </>
        )}
      </Button>
    </Box>
  );
}

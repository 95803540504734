import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

import { Box, Link, Skeleton, Typography } from "@mui/material";

import { Whatshot } from "@mui/icons-material";

export default function LeaderboardTable({
  results,
  loading,
  title = "Leaderboard",
  initialState,
}) {
  const columns = [
    {
      field: "analyst",
      type: "string",
      width: 140,
    },
    {
      field: "date",
      type: "date",
      width: 100,
      valueGetter: (value) => {
        try {
          return new Date(value + "T00:00:00-05:00");
        } catch (error) {
          return null;
        }
      },
    },
    {
      field: "search",
      width: 180,
      renderCell: (params) => (
        // console.log(params),
        <Link
          href={`/search?label=${encodeURIComponent(
            params.value
          )}&currentView=land`} // Make params.value URI-safe
          style={{
            cursor: "pointer",
            textDecoration: "none",
            // color: "#04FFFF",
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "reject",
      headerName: "Reject",
      type: "number",
      width: 80,
      headerClassName: "weekly--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "advance",
      headerName: "Review",
      type: "number",
      width: 80,
      headerClassName: "weekly--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "rating",
      headerName: "Rated",
      type: "number",
      width: 80,
      headerClassName: "trailing--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      type: "number",
      width: 80,
      headerClassName: "auto--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "send",
      headerName: "Client Inbox",
      type: "number",
      width: 120,
      headerClassName: "auto--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "client_approve",
      headerName: "Approved",
      type: "number",
      width: 80,
      headerClassName: "trailing--header",
      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
    },
    {
      field: "hours",
      // headerName: "Hours",
      type: "number",
      width: 80,
      // headerClassName: "trailing--header",

      cellClassName: (params) => {
        if (params.value == 0) {
          return "number-is-zero";
        }
      },
      valueGetter: (value) => {
        return parseFloat(value);
      },
      valueFormatter: (value) => {
        return value?.toFixed(1) || 0;
      },
    },
  ];

  return (
    <Box
      sx={{
        px: 4,
        "& .number-is-zero": {
          color: "#4E4B4B",
        },
        "& .trailing--header": {
          color: "#9A5AEF",
        },
        "& .weekly--header": {
          color: "#9A5AEF",
        },
        "& .auto--header": {
          color: "#04FFFF",
        },
        "& .daily--header": {
          color: "#bdbdbd",
        },
        minWidth: 800,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Whatshot color="secondary" sx={{ fontSize: 24 }} />
        <Typography variant="h5" sx={{ my: 2 }}>
          {title}
        </Typography>
      </Box>

      {loading && (
        <Skeleton
          variant="rectangular"
          height={540}
          style={{ borderRadius: "5px" }}
        />
      )}

      {!loading && (
        <DataGridPremium
          rows={results}
          columns={columns}
          getRowId={(row) => row.index}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
          pagination
          pageSizeOptions={[5, 10, 20, 50, 80]}
          sx={{
            "& .MuiDataGrid-aggregationColumnHeaderLabel": {
              color: "white",
              opacity: 0.6,
              fontSize: "10px",
            },

            "& .MuiDataGrid-footerCell": {
              color: "#04FFFF",
            },
          }}
          initialState={initialState}
        />
      )}
    </Box>
  );
}
